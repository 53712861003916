import lfchkLogo from "./olscbannertop0.jpeg"
import Social from "./Social"
import "./App.css"

export default function App() {
	return (
		<div className="mx-auto relative flex flex-col justify-start pt-32 px-10 items-center font-normal text-lg text-gray-900 max-w-4xl">
			<div><img className="relative z-0" width="600" src={lfchkLogo} alt="lfchk" /></div>
			<Social />
		</div>
	)
}
